import Vue from 'vue'

// axios
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
// import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

// const jwtConfig = { ...jwtDefaultConfig }
// const tokenLacalStorage = sessionStorage.getItem('token')
let tokenLacalStorage = null
if (localStorage.getItem('userInfo') == 'null' || localStorage.getItem('userInfo') == null) {
  localStorage.clear()
} else {
  tokenLacalStorage = JSON.parse(localStorage.getItem('userInfo')).token
}

// const axiosIns = axios.create({
//   // You can add your headers here
//   // ================================
//   baseURL: 'https://stg-amz-api.kec-app.com',
//   timeout: 10000,
//   headers: { 'X-Custom-Header': 'foobar' },
// })

function conf(base = {}) {
  if (process.env.NODE_ENV === 'production') { // 生产环境下
    //const env = process.env.ENV_CONFIG || 'dev'
    return base.production
  } else {
    return base.dev
  }
}
// PORTAL 接口
const POR_LOGIN_LOGOUT = conf({
  // dev: 'http://stg.as2.kec-app.com:8088',
  dev: 'https://stg-openapi.kec-app.com',
  // dev: 'http://172.16.0.7:8088',
  production: 'https://openapi.kec-app.com',
})

// Request Interceptor
axios.interceptors.request.use(config => {
    // sessionStorage.clear()
    if (tokenLacalStorage !== null) {
      // axios.defaults.headers.Authorization = jwtConfig.tokenType + tokenLacalStorage
      // axios.defaults.headers.Authorization = tokenLacalStorage
    }
    return config
  },
  error => Promise.reject(error),
)

// Response Interceptor
axios.interceptors.response.use(res => {
  const code = res.data.code
  if (code === '200' || code === '1001') {
    return res
  } else if (code === '402') {
    // Vue.$toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: res.data.message,
    //     icon: 'XIcon',
    //     variant: 'danger',
    //   },
    // })
    localStorage.clear()
    router.push({ name: 'auth-login' })
  } else if (code === '405') {
    // Vue.$toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: res.data.message,
    //     icon: 'XIcon',
    //     variant: 'danger',
    //   },
    // })
    router.push({ name: 'no-permission' })
  } else {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: res.data.message,
        icon: 'XIcon',
        variant: 'danger',
      },
    })
    return res
  }
})

axios.defaults.baseURL = POR_LOGIN_LOGOUT
axios.defaults.timeout = 10000
axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.responseType = 'json'
axios.defaults.headers.Authorization = tokenLacalStorage

// Vue.prototype.$http = axiosIns
Vue.prototype.$http = axios

export default axios
