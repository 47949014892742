export default [
  {
    path: '/config-center/user/userList',
    name: 'user-list',
    component: () => import('@/views/config-center/user/userList.vue'),
  },
  {
    path: '/config-center/user/userList/createUser',
    name: 'create-user',
    component: () => import('@/views/config-center/user/components/createUser.vue'),
  },
  {
    path: '/config-center/user/userList/userDetail/:userId',
    name: 'user-detail',
    component: () => import('@/views/config-center/user/components/userDetail.vue'),
  },
  {
    path: '/config-center/user/userList/userAccount/:userId',
    name: 'user-account',
    component: () => import('@/views/config-center/user/components/userAccount.vue'),
  },
  {
    path: '/config-center/user/role/createRole',
    name: 'create-role',
    component: () => import('@/views/config-center/user/components/createRole.vue'),
  },
  {
    path: '/config-center/user/role/editRole/:roleId',
    name: 'role-detail',
    component: () => import('@/views/config-center/user/components/roleDetail.vue'),
  },
  {
    path: '/config-center/masterData/externalCode',
    name: 'external-code',
    component: () => import('@/views/config-center/masterData/externalCode.vue'),
  },
  {
    path: '/config-center/masterData/externalCode/createExternal',
    name: 'create-external',
    component: () => import('@/views/config-center/masterData/components/createExternal.vue'),
  },
  {
    path: '/config-center/masterData/externalCode/externalDetails/:id',
    name: 'external-detail',
    component: () => import('@/views/config-center/masterData/components/externalDetails.vue'),
  },
  {
    path: '/config-center/masterData/statusCode',
    name: 'status-code',
    component: () => import('@/views/config-center/masterData/statusCode.vue'),
  },
  {
    path: '/config-center/masterData/statusCode/createStatus',
    name: 'create-status',
    component: () => import('@/views/config-center/masterData/components/createStatus.vue'),
  },
  {
    path: '/config-center/masterData/statusCode/statusDetails/:id',
    name: 'status-detail',
    component: () => import('@/views/config-center/masterData/components/statusDetails.vue'),
  },
  {
    path: '/config-center/masterData/masterCodeData',
    name: 'master-code-data',
    component: () => import('@/views/config-center/masterData/masterCodeData.vue'),
  },
  {
    path: '/config-center/masterData/masterCodeType',
    name: 'master-code-type',
    component: () => import('@/views/config-center/masterData/masterCodeType.vue'),
  },
  {
    path: '/config-center/user/role',
    name: 'role-list',
    component: () => import('@/views/config-center/user/role.vue'),
  },
  {
    path: '/config-center/configuration/serviceRoute',
    name: 'service-route',
    component: () => import('@/views/config-center/configuration/serviceRoute.vue'),
  },
  {
    path: '/config-center/configuration/serviceRoute/createServiceRoute',
    name: 'create-service-route',
    component: () => import('@/views/config-center/configuration/components/createSerRoute.vue'),
  },
  {
    path: '/config-center/configuration/routingService',
    name: 'routing-service',
    component: () => import('@/views/config-center/configuration/routingService.vue'),
  },
  {
    path: '/config-center/configuration/routingServiceModify/:id',
    name: 'routing-service-modify',
    component: () => import('@/views/config-center/configuration/components/routingServiceModify.vue'),
  },
  {
    path: '/config-center/configuration/SLA',
    name: 'sla-list',
    component: () => import('@/views/config-center/configuration/sla.vue'),
  },
  {
    path: '/config-center/configuration/SLA/createSLA',
    name: 'create-sla',
    component: () => import('@/views/config-center/configuration/components/createSla.vue'),
  },
  {
    path: '/config-center/configuration/SLA/slaDetail/:id',
    name: 'sla-detail',
    component: () => import('@/views/config-center/configuration/components/slaDetails.vue'),
  },
  {
    path: '/config-center/configuration/autoReport',
    name: 'auto-report',
    component: () => import('@/views/config-center/configuration/autoReport.vue'),
  },
  {
    path: '/config-center/configuration/autoReport/createAutoReport',
    name: 'create-autoReport',
    component: () => import('@/views/config-center/configuration/components/createReport.vue'),
  },
  {
    path: '/config-center/configuration/autoReport/autoReportDetail/:id',
    name: 'autoReport-detail',
    component: () => import('@/views/config-center/configuration/components/reportDetails.vue'),
  },
  {
    path: '/config-center/masterData/selfpick',
    name: 'self-pick',
    component: () => import('@/views/config-center/masterData/selfpick.vue'),
  },
  {
    path: '/config-center/masterData/selfpick/selfpickDetails/:id',
    name: 'self-pick-detail',
    component: () => import('@/views/config-center/masterData/components/selfpickDetails.vue'),
  },
  {
    path: '/config-center/configuration/invoice',
    name: 'invoice',
    component: () => import('@/views/config-center/configuration/invoice.vue'),
  },
  {
    path: '/config-center/configuration/invoice/createInvoice',
    name: 'create-invoice',
    component: () => import('@/views/config-center/configuration/components/createInvoice.vue'),
  },
]
